import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Button, Flex, Table } from 'antd';
import { EditTwoTone, CheckOutlined, DownCircleTwoTone, UpCircleTwoTone } from '@ant-design/icons';
import CXLoading from "components/CXLoading";
import { apiGetWithToken, apiPostWithToken, getApiUrl } from "lib/api";
import dayjs from 'dayjs';
import CXTableWrapper from "components/CXTableWrapper";
import { EditQuestion } from "./EditQuestion";

export default function AdminQuestions() {
    const bundleId = parseInt(useParams().bundleId);
    const { site, siteLoading, setBreadcrumb } = useOutletContext();
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [bundle, setBundle] = useState({});
    const [questions, setQuestions] = useState([]);
    const [isEditQuestionOpen, setIsEditQuestionOpen] = useState(false);
    const [editQuestion, setEditQuestion] = useState(null);

    useEffect(() => {
        if (siteLoading || !site || !bundleId) return;

        setRefresh(false);

        // Create an abort controller for the async api call.
        const controller = new AbortController();
        const signal = controller.signal;

        const url = getApiUrl(`/api/site/${site.siteId}/bundle/${bundleId}`);
        apiGetWithToken(url,
            () => { setLoading(true); },
            (data) => {
                // console.log('results', data);
                setQuestions(data.questions);
                setBundle(data.bundle);
            },
            (err) => { },
            () => { setLoading(false); },
            null,
            signal,
        );

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            // Abort in progress apii calls
            // console.debug('Aborting bundle load');
            controller.abort();
        }

    }, [siteLoading, site, bundleId, refresh]);


    useEffect(() => {
        if (siteLoading || !site || !bundle) return;

        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Bundles': '/admin/' + site.siteId + '/bundles',
            [bundle.name]: '/admin/' + site.siteId + '/bundle/' + bundle.bundleId,
        });
    }, [siteLoading, site, setBreadcrumb, bundle]);


    function moveQuestion(question, direction) {
        const url = getApiUrl(`/api/site/${site.siteId}/question/${question.questionId}/move?direction=${direction}`);
        apiPostWithToken(url, [],
            () => { setLoading(true); },
            (data) => {
                // console.log(data);

                setRefresh(true);
            },
            (err) => { setLoading(false); },
            () => { },
        );
    }

    if (siteLoading || !bundle) {
        return <CXLoading text="Loading..." />
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'order',
            key: 'order',
            minWidth: '10px',
            sorter: (a, b) => a.order > b.order,
        },
        {
            title: 'Name',
            dataIndex: 'description',
            key: 'description',
            sorter: (a, b) => a.description > b.description,
            minWidth: '200px',
        },
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.question > b.question,
        },
        {
            title: 'Show',
            dataIndex: 'showInDrawer',
            key: 'showInDrawer',
            width: '20px',
            render: (_, question) => {
                return question.showInDrawer ? <CheckOutlined /> : <></>;
            },
        },
        {
            title: 'Tag',
            dataIndex: 'tagEnabled',
            key: 'tagEnabled',
            width: '20px',
            render: (_, question) => {
                return question.tagEnabled ? <CheckOutlined /> : <></>;
            },
        },
        {
            title: 'Created',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            width: '120px',
            render: (_, bundle) => {
                if (!bundle.dateCreated) {
                    return (<></>);
                }
                return dayjs(bundle.dateCreated.date).format('MM/DD/YYYY');
            },
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: '80px',
            render: (_, question, i) => {
                return (
                    <Flex gap="none" style={{ whiteSpace: 'nowrap' }}>
                        <Button style={{ width: '10px', visibility: i === questions.length - 1 ? 'hidden' : 'visible' }} type="text" icon={<DownCircleTwoTone />} onClick={(ev) => {
                            ev.preventDefault();
                            moveQuestion(question, 'down');
                        }}
                        />
                        <Button style={{ visibility: i === 0 ? 'hidden' : 'visible' }} type="text" icon={<UpCircleTwoTone />} onClick={(ev) => {
                            ev.preventDefault();
                            moveQuestion(question, 'up');
                        }}
                        />
                        <Button type="text" icon={<EditTwoTone />} onClick={(ev) => {
                            ev.preventDefault();
                            setEditQuestion(question);
                            setIsEditQuestionOpen(true);
                        }}
                        />
                    </Flex>
                );
            },
        },
    ];


    return (
        <>
            <EditQuestion bundleId={bundle.bundleId} question={editQuestion} setQuestion={setEditQuestion} site={site} setRefresh={setRefresh} open={isEditQuestionOpen} setOpen={setIsEditQuestionOpen} />

            <CXTableWrapper>
                <CXTableWrapper.Header>
                    <Flex flex="1">
                        <Flex flex="1">
                            <div style={{ fontSize: '1.25em', fontWeight: 'bold' }}>Question Bundle: {bundle.name}</div>
                        </Flex>
                        <Flex>
                            <Button onClick={() => { setEditQuestion(null); setIsEditQuestionOpen(true); }}>Create New Question</Button>
                        </Flex>
                    </Flex>
                </CXTableWrapper.Header>
                <Flex vertical flex="1">
                    <Flex vertical gap="small" style={{ padding: '10px', backgroundColor: '#ddd' }}>
                        <Flex>
                            {bundle.description}
                        </Flex>
                    </Flex>
                    <Flex>
                        <Table style={{ width: '100%', minWidth: '250px', borderWidth: '1px', borderStyle: 'solid', borderColor: '#aaa', }}
                            rowKey='questionId'
                            loading={loading}
                            size="middle"
                            // showHeader={false}
                            columns={columns}
                            dataSource={questions}
                            pagination={false}
                        />
                    </Flex>
                </Flex>
                <CXTableWrapper.Footer>
                </CXTableWrapper.Footer>
            </CXTableWrapper>

        </>
    );
};
