import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, List, Typography } from 'antd';
import { getApiUrl, apiGetWithToken } from 'lib/api';


export const DashboardWelcome = () => {
    return (
        <div>
            Welcome to your Cleary Gottlieb Extranet. This extranet has been created
            to provide easy access to valuable content and we hope you will find it
            useful and access it regularly. If you have any comments or requests for
            additional materials please contact your regular Cleary team members or
            contact our extranet administrators.
            <br />
            <br />

            Click on the link(s) on the left hand side of the screen to enter your site.
            <br />
            <br />

            Our Offices
            <ul>
                <li>New York</li>
                <li>Washington</li>
                <li>Paris</li>
                <li>Brussels</li>
                <li>London</li>
                <li>Frankfurt</li>
                <li>Cologne</li>
                <li>Rome</li>
                <li>Milan</li>
                <li>Hong Kong</li>
                <li>Beijing</li>
                <li>Buenos Aires</li>
                <li>São Paulo</li>
                <li>Abu Dhabi</li>
                <li>Seoul</li>
                <li>Bay Area</li>
            </ul>
        </div>
    );
};
