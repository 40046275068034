import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const AdminSettingsSecurity = () => {
    const { site, siteLoading, setBreadcrumb } = useOutletContext();

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Settings': '',
            'Security': '/admin/' + site.siteId + '/settings/security',
        });
    }, [siteLoading, site, setBreadcrumb]);

    return <h1>Site Settings - Security</h1>;
};

export default AdminSettingsSecurity;