import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import CXLoading from "components/CXLoading";
import { apiGetWithToken, generateQueryUrl } from 'lib/api';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const CXBarchart = ({ title, siteId, tableId, viewId, primary, secondary, filter, primaryLabel, secondaryLabels, limit, bgColors, bgStrokeColors, activeBgColors, activeStrokeColors, verboseLogging, setApiUrl, messageApi }) => {
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState([]);
  const [xAxisKey, setxAxisKey] = useState('');
  const [xAxisName, setxAxisName] = useState('');
  const [yAxisKeys, setyAxisKeys] = useState([]);
  const [yAxisNames, setyAxisNames] = useState([]);


  useEffect(() => {
    if (!loading) {
      verboseLogging && console.log('Not loading');
      return;
    }
    if (!siteId) {
      verboseLogging && console.log('No siteId');
      return;
    }
    if (!tableId && !viewId) {
      verboseLogging && console.log('No tableId or viewId');
      return;
    }

    verboseLogging && console.log('Fetching graph data...');

    const url = generateQueryUrl(siteId, viewId, tableId, primary, secondary, filter, limit);
    setApiUrl && setApiUrl(url.toString());
    apiGetWithToken(url.toString(),
      () => { setLoading(true); },
      (data) => {
        verboseLogging && console.log(data);

        let yKeys = [];
        let yNames = [];
        for (let i = 0; i < data.view.fieldList.length; i++) {
          if (i === 0) {
            setxAxisKey(data.view.fieldList[i].internalName);
            setxAxisName(data.view.fieldList[i].name);
            verboseLogging && console.log("Set x-axis key to " + data.view.fieldList[i].internalName + ' (' + data.view.fieldList[i].name + ")");
          } else {
            yKeys.push(data.view.fieldList[i].internalName);
            yNames.push(data.view.fieldList[i].name);
            verboseLogging && console.log("Added y-axis key " + data.view.fieldList[i].internalName + " (" + data.view.fieldList[i].name + ")");
          }
        }
        setyAxisKeys(yKeys);
        setyAxisNames(yNames);

        setTable(data.view.fieldValues);
      },
      (err) => {
        console.log(err);
        messageApi && messageApi.error(err.message);
      },
      () => { setLoading(false); }
    );

  }, [loading, siteId, tableId, viewId, verboseLogging, setApiUrl, filter, primary, secondary, limit, messageApi]);


  function fillColors(passed, defaultColor) {
    let arr = passed ? passed.split(',') : [];
    for (let i = arr.length; i < yAxisKeys.length; i++) {
      arr[i] = defaultColor;
    }
    return arr;
  }

  // Make sure we have enough of all the color values.
  const bgColorArr = fillColors(bgColors, '#55f');
  const bgStrokeArr = fillColors(bgStrokeColors, '#333');
  const activeStrokeArr = fillColors(activeStrokeColors, '#555');
  const activeBgArr = fillColors(activeBgColors, '#77e');

  const xAxisLabel = primaryLabel ? primaryLabel : xAxisName;
  let yAxisLabels = yAxisNames;
  if (secondaryLabels) {
    const l = secondaryLabels.split(',');
    for (let i = 0; i < l.length; i++) {
      yAxisLabels[i] = l[i];
    }
  }

  const Inside = () => {
    if (loading) {
      return <CXLoading text="Loading graph..." />
    }

    return (
      <ResponsiveContainer width={'99%'} height={300}>
        <BarChart
          data={table}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisKey} name={xAxisLabel} />
          <YAxis />
          <Tooltip />
          <Legend />
          {yAxisKeys.map((name, i) => {
            return (
              <Bar
                key={name}
                dataKey={name}
                name={yAxisLabels[i]}
                fill={bgColorArr[i]}
                stroke={bgStrokeArr[i]}
                activeBar={
                  <Rectangle fill={activeBgArr[i]} stroke={activeStrokeArr[i]} />
                }
              />
            )
          })}

        </BarChart>
      </ResponsiveContainer>
    );
  }

  if (!title) {
    return <Inside />;
  }
  return (
    <>
      <Card title={title} style={{ width: '100%' }}>
        <Inside />
      </Card>
    </>
  );
};

export default CXBarchart;