import React, { useState, useEffect } from "react";
import { Modal, Form, Input, message } from 'antd';
import { getApiUrl, apiPostWithToken } from 'lib/api';

export const CreateFolder = ({ open, setOpen, siteId, parentId, refresh }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [enabled, setEnabled] = useState(true);
    const [form] = Form.useForm();

    // Clear the form when opened or closed.
    useEffect(() => {
        if (open) {
            form.resetFields();
            setTimeout(() => { form.getFieldInstance('name').focus(); }, 100);
        }
    }, [open, form]);


    async function onCreate(values) {
        const url = getApiUrl(`/api/site/${siteId}/file/${parentId}/create`);
        apiPostWithToken(url, values,
            () => { setEnabled(false); },
            (data) => {
                console.log(data);
                if (data.code === 200) {
                    refresh();
                    messageApi.info('Folder created');
                    setOpen(false);
                } else {
                    messageApi.error(data.message);
                }
            },
            (err) => { messageApi.error(err); },
            () => { setEnabled(true); }
        );

        return false;
    }

    return (
        <>
            {contextHolder}
            <Modal
                title="Create Folder"
                centered
                open={open}
                width="600px"
                onCancel={() => { setOpen(false); }}
                onOk={form.submit}
                okButtonProps={{ disabled: !enabled }}
                cancelButtonProps={{ disabled: !enabled }}
            >

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onCreate}
                    disabled={!enabled}
                >
                    <br />
                    <Form.Item name="name" label="Folder Name" rules={[{ required: true }]} tooltip="The name of the new folder">
                        <Input />
                    </Form.Item>

                </Form >

            </Modal >
        </>
    );
};
