import React, { useState, useEffect, useCallback } from "react";
import { Progress, Modal } from 'antd';
import { getApiUrl, apiGetWithToken, parseJsonChunk, getAuthorizationHeader } from 'lib/api';
import axios from "axios";

export function DownloadFiles({ site, open, setOpen, tableItemIds }) {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('');
    const [okButtonText, setOkButtonText] = useState('Delete');
    const [cancelButtonText, setCancelButtonText] = useState('Cancel');
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [okButtonDisplay, setOkButtonDisplay] = useState('inline');
    const [progressVisible, setProgressVisible] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);


    const getDownloadFile = useCallback((signal, folderId, fileId, filename, filesize) => {

        async function createDownloadLink(data, filename) {
            console.log('Creating blob...');
            const file = new Blob([data], { type: 'application/zip' });
            console.debug('File is ' + file.size + ' bytes');
            const element = document.createElement("a");
            element.href = URL.createObjectURL(file);
            element.download = filename;
            // simulate link click
            document.body.appendChild(element); // Required for this to work in FireFox
            console.debug('Simulating click to download');
            element.click();
            setOpen(false);
        }

        const url = getApiUrl(`/api/site/${site.siteId}/file/${folderId}/download/${fileId}`);
        axios.get(url, {
            signal: signal,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': getAuthorizationHeader(),
            },
            onDownloadProgress: (e) => {
                console.log(e.loaded, ' of ', filesize);
                setProgressPercent(Math.round(66 + (e.loaded / filesize * 33.3)));
            }
        })
            .then(function (response) {
                // console.log('RESP', response);
                if (response.status === 200) {
                    setProgressPercent(100);
                    createDownloadLink(response.data, filename);
                }
                return true;
            })
            .catch(function (error) {
                console.log('ERR', error);
                return true;
            });
    }, [site, setOpen]);


    const startDownload = useCallback((signal) => {
        setConfirmLoading(true);
        // setButtonsDisabled(true);
        var url = new URL(getApiUrl(`/api/site/${site.siteId}/files/download`));
        url.searchParams.append('tableItemIds', tableItemIds.join(','));

        apiGetWithToken(url.toString(),
            null,
            (data) => { },
            (err) => {
                console.error(err);
                setModalText('An unexpected error occurred while trying to download the files.  Please refresh the page and try again.');
                setOkButtonText('OK');
                setButtonsDisabled(false);
                setConfirmLoading(false);
            },
            null,
            (progress, chunk) => {
                parseJsonChunk(chunk, (o) => {
                    if (o.done) {
                        setProgressPercent(66);
                        setModalText('Downloading the files from the server...');
                        setCancelButtonText('Cancel');
                        setButtonsDisabled(false);
                        setConfirmLoading(false);
                        getDownloadFile(signal, o.folderId, o.tableItemId, o.filename, o.filesize);
                    } else {
                        if (o.part2progress > 0) {
                            setModalText('Saving the compressed files...');
                        }
                        setProgressPercent(Math.round(o.part1progress / o.part1total * 33.3) + Math.round(o.part2progress / o.part2total * 33.3));
                    }
                });
            },
            signal,
        );
    }, [site, tableItemIds, getDownloadFile]);


    // Reset when opened or closed
    useEffect(() => {
        setModalText('Preparing files for download...');
        setOkButtonText('Download');
        setCancelButtonText('Cancel');
        setButtonsDisabled(false);
        setOkButtonDisplay('none');
        setProgressVisible(open);
        setProgressPercent(0);

        // Create an abort controller for the async api calls.
        const controller = new AbortController();
        const signal = controller.signal;

        if (open) {
            startDownload(signal);
        }
        return () => {
            // Abort in progress apii calls
            controller.abort();
        }
    }, [open, site, tableItemIds, startDownload]);

    return (
        <Modal
            title="Downloading Files"
            open={open}
            centered
            confirmLoading={confirmLoading}
            okText={okButtonText}
            // onOk={() => { }}
            okButtonProps={{ style: { display: okButtonDisplay } }}
            cancelText={cancelButtonText}
            onCancel={() => { setOpen(false); }}
            cancelButtonProps={{ disabled: buttonsDisabled, }}
            closable={progressPercent === 0}
            keyboard={progressPercent === 0}
            maskClosable={progressPercent === 0}
        >
            <p>{modalText}</p>
            <Progress percent={progressPercent} style={{ display: progressVisible ? 'block' : 'none' }} />
        </Modal >
    );
}