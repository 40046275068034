import React, { useState, useEffect } from "react";
import { Card, Drawer } from 'antd';
import Markdown from 'react-markdown'
import { getApiUrl, apiGetWithToken } from 'lib/api';

export const FileDrawer = ({ siteId, file, open, setOpen }) => {
    const [loadingAnswers, setLoadingAnswers] = useState(true);
    const [loadingMetadata, setLoadingMetadata] = useState(true);
    const [answers, setAnswers] = useState([]);
    const [metadata, setMetadata] = useState([]);

    // Clear the form when opened or closed.
    useEffect(() => {
        if (!open) {
            return;
        }
        if (file.answers && file.answers > 0) {
            const url = getApiUrl(`/api/site/${siteId}/file/${file.tableItemId}/answers`);
            apiGetWithToken(url,
                () => { setLoadingAnswers(true); },
                (data) => {
                    console.log(data);
                    if (data.code === 200) {
                        for (let i = 0; i < data.answers.length; i++) data.answers[i].key = 'ans' + i;
                        setAnswers(data.answers);
                    }
                },
                (err) => { },
                () => { setLoadingAnswers(false); }
            );
        } else {
            setLoadingAnswers(false);
        }

        if (file.metadata && file.metadata > 0) {
            const url = getApiUrl(`/api/site/${siteId}/file/${file.tableItemId}/metadata`);
            apiGetWithToken(url,
                () => { setLoadingMetadata(true); },
                (data) => {
                    console.log(data);
                    if (data.code === 200) {
                        for (let i = 0; i < data.metadata.length; i++) data.metadata[i].key = 'meta' + i;
                        setMetadata(data.metadata);
                    }
                },
                (err) => { },
                () => { setLoadingMetadata(false); }
            );
        } else {
            setLoadingMetadata(false);
        }

        return () => {
            setAnswers([]);
            setMetadata([]);
        }
    }, [open, file, siteId]);

    const QuestionAnswers = () => {
        if (loadingAnswers || loadingMetadata || !answers || !metadata) {
            return (<b>Loading...</b>);
        }

        const MetadataComments = ({ m }) => {
            if (m.metadata_comments) {
                return (
                    <>
                        <div style={{ border: '1px #eee solid', backgroundColor: '#f5f5f5', padding: '20px' }}>
                            <i>{m.metadata_comments}</i>
                        </div>
                        <br />
                    </>
                );
            } else {
                return <></>;
            }
        }

        return (
            <>
                <hr />
                {
                    answers.map((a) => {
                        console.log(a);
                        if (!a.showInDrawer) {
                            return <></>;
                        }
                        return (
                            <>
                                <Card title={a.description}>
                                    <Markdown>{a.answer}</Markdown>
                                </Card>
                                <br />
                            </>
                        );
                    })}
                <hr />
                {
                    metadata.map((m) => {
                        return (
                            <>
                                <Card title={m.metadata_key}>
                                    <div>{m.metadata_value}</div><br />
                                    <MetadataComments m={m} />

                                    <div style={{ fontSize: '0.7em' }}>Source: {m.metadata_source}</div>
                                </Card>
                                <br />
                            </>
                        );
                    })}
            </>

        );
    }

    return (
        <Drawer width={540} placement="right" title="File at a glance" open={open} onClose={() => { setOpen(false); }}>
            <p><b>Name:</b> {file.name}</p>
            <p><b>Internal ID:</b> {file.tableItemId}</p>
            <QuestionAnswers />
        </Drawer>
    );
};
