import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, DatePicker, Form, Input, Select, Switch, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getApiUrl, apiGetWithToken, apiPostWithToken, getAuthorizationHeader } from 'lib/api';
import dayjs from 'dayjs';
import CXLoading from "components/CXLoading";
import CXLogo from "components/CXLogo";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

function defaultSelectedDates(site) {
    if (!site || !site.startDate || !site.endDate) {
        return [];
    }
    return [
        dayjs(site.startDate.date),
        dayjs(site.endDate.date)
    ];
}

const AdminSettingsGeneral = () => {
    const [saving, setSaving] = useState(false);
    const { site, siteId, setSite, siteLoading, setBreadcrumb } = useOutletContext();
    const [selectedDates, setSelectedDates] = useState(defaultSelectedDates(site));
    const [messageApi, contextHolder] = message.useMessage();

    // Populate a new field for the Form initialValues array.
    if (site) {
        site.activeDates = defaultSelectedDates(site);
    }

    function onChangeRangePicker($params) {
        setSelectedDates($params);
    }

    async function onSubmit(values) {
        console.log(values);

        // Extract the range picker values
        console.log(selectedDates);
        if (selectedDates && selectedDates.length === 2) {
            values.startDate = selectedDates[0].format('YYYY-MM-DD');
            values.endDate = selectedDates[1].format('YYYY-MM-DD');
        } else {
            values.startDate = '';
            values.endDate = '';
        }
        delete values.activeDates;

        let url = getApiUrl('/api/site/' + site.siteId + '/settings');
        apiPostWithToken(url, values,
            () => { setSaving(true); },
            (data) => { messageApi.info('Site settings were saved.'); },
            (err) => { console.log(err); messageApi.error('An unknown error occurred saving the settings, please try again.'); },
            () => { }
        );

        url = getApiUrl('/api/site/' + site.siteId);
        apiGetWithToken(url,
            () => { },
            (data) => { console.log('Updating site...'); console.log('site', data.site); setSite(data.site); },
            (err) => { console.log(err); messageApi.error('An unknown error occurred saving the settings, please try again.'); },
            () => { setSaving(false); }
        );
    }

    const props = {
        name: 'logo',
        action: getApiUrl('/api/site/' + siteId + '/logo'),
        maxCount: 1,
        headers: {
            'Authorization': getAuthorizationHeader(),
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    function normFile(e) {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Settings': '',
            'General': '/admin/' + site.siteId + '/settings/general',
        });

        // Once we're sure that site is valud, set the selected dates state.
        setSelectedDates(defaultSelectedDates(site));

    }, [siteLoading, site, setBreadcrumb]);

    if (siteLoading || !site) {
        return <CXLoading text="Loading settings..." />
    }

    const LogoDiv = () => {
        if (!site.hasLogo) {
            return (<></>);
        }
        return (<div><CXLogo siteId={siteId}></CXLogo></div>);
    }

    return (
        <>
            {contextHolder}
            <h1>Site Settings - General</h1>
            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                onFinish={onSubmit}
                labelWrap="true"
                disabled={saving}
                initialValues={site}
            >
                <Form.Item name='enabled' label="Enabled" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Form.Item name="name" label="Site Name" rules={[{ required: true }]} tooltip="The name of the site">
                    <Input />
                </Form.Item>

                <Form.Item name='status' label="Status">
                    <Select>
                        <Select.Option value="active">Active</Select.Option>
                        <Select.Option value="archived">Archived</Select.Option>
                        <Select.Option value="readonly">Read Only</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item name="description" label="Description">
                    <TextArea rows={4} />
                </Form.Item>

                <Form.Item name="notes" label="Notes">
                    <TextArea rows={3} />
                </Form.Item>

                <Form.Item label="Logo" valuePropName="fileList2" getValueFromEvent={normFile}>
                    <LogoDiv />
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>

                <Form.Item name="activeDates" label="Active Dates">
                    <RangePicker
                        // defaultValue={selectedDates}
                        onChange={onChangeRangePicker}
                        allowEmpty
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
                    <Button type="primary" htmlType="submit">Save</Button>
                </Form.Item>
                {/* 
                <hr></hr>


                <Form.Item label="Checkbox" name="disabled" valuePropName="checked">
                    <Checkbox>Checkbox</Checkbox>
                </Form.Item>
                <Form.Item label="Radio">
                    <Radio.Group>
                        <Radio value="apple"> Apple </Radio>
                        <Radio value="pear"> Pear </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Input">
                    <Input />
                </Form.Item>
                <Form.Item label="Select">
                    <Select>
                        <Select.Option value="demo">Demo</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="TreeSelect">
                    <TreeSelect
                        treeData={[
                            { title: 'Light', value: 'light', children: [{ title: 'Bamboo', value: 'bamboo' }] },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="Cascader">
                    <Cascader
                        options={[
                            {
                                value: 'zhejiang',
                                label: 'Zhejiang',
                                children: [
                                    {
                                        value: 'hangzhou',
                                        label: 'Hangzhou',
                                    },
                                ],
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="DatePicker">
                    <DatePicker />
                </Form.Item>
                <Form.Item label="RangePicker">
                    <RangePicker />
                </Form.Item>
                <Form.Item label="InputNumber">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="TextArea">
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item label="Switch" valuePropName="checked">
                    <Switch />
                </Form.Item>
                {/* <Form.Item label="Upload" valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload action="/upload.do" listType="picture-card">
                        <button style={{ border: 0, background: 'none' }} type="button">
                            <PlusOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </button>
                    </Upload>
                </Form.Item> */}
                {/* <Form.Item label="Button">
                    <Button>Button</Button>
                </Form.Item>
                <Form.Item label="Slider">
                    <Slider />
                </Form.Item>
                <Form.Item label="ColorPicker">
                    <ColorPicker />
                </Form.Item> */}
            </Form >
        </>
    );

};

export default AdminSettingsGeneral;
