import React, { useState, useEffect } from 'react';
import { Grid, Typography, theme, Modal, Spin } from "antd";
import { setCookie, eraseCookie } from 'lib/cookies.js';
import { getAuthorizationHeader, apiPostWithToken, getApiUrl } from 'lib/api';
import axios from 'axios';

import LoginDialog from "pages/login/LoginDialog";
import LoginSSO from "pages/login/LoginSSO";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text } = Typography;

export default function LoginPage() {
    const { token } = useToken();
    const screens = useBreakpoint();
    const [loading, setLoading] = useState(false);
    const [loginMessage, setLoginMessage] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [loginProgressOpen, setLoginProgressOpen] = useState(false);
    const [networkMessage, setNetworkMessage] = useState("");

    useEffect(() => {
        eraseCookie('token');
        eraseCookie('email');
    }, []);

    async function tryToLogin(url, values) {
        const ret = await apiPostWithToken(
            url,
            values,
            () => {
                setLoginMessage('');
                setNetworkMessage('Unknown error');
                setLoginProgressOpen(true);
                setLoading(true);
            },
            (data) => {
                if (data.ok === true) {
                    const expires = (values.remember === true) ? 10 : 0;
                    setCookie('token', data.token, expires);
                    setCookie('email', data.email, expires);
                    setTimeout(() => {
                        // Give the warmup request a shot at getting through.
                        window.location.href = "/dashboard";
                    }, 1000);
                    return true;
                } else {
                    setLoginMessage(data.message);
                    return true;
                }
            },
            (msg) => { return msg; },
            () => {
                setLoading(false);
                setLoginProgressOpen(false);
            },
        );

        if (ret !== true) {
            setNetworkMessage(ret);
            setModalOpen(true);
            console.log(ret);
        }
        return ret;
    }

    const styles = {
        vertical: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            //width: "660px",
            backgroundColor: token.colorBgContainer,
            display: "flex",
            flexDirection: "column",
        },
        container: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            //width: "660px",
            backgroundColor: token.colorBgContainer,
            display: "flex",
        },
        section: {
            alignItems: "center",
            backgroundColor: token.colorBgBase,
            display: "flex",
            height: screens.sm ? "100vh" : "auto",
            padding: "0px"
        },
        line: {
            content: "",
            backgroundColor: "#444",
            width: "1px",
            display: "block",
            marginLeft: "40px",
            marginRight: "40px",
        },
        text: {
            color: token.colorTextSecondary,
            textAlign: 'center',
        },
    };

    return (
        <>
            <Modal
                title="Network Error"
                centered
                open={modalOpen}
                cancelButtonProps={{ style: { display: 'none' } }}
                onOk={() => setModalOpen(false)}
            >
                <p>An error occurred contacting the login server, please try again later.</p>
                <p>Message: <b>{networkMessage}</b></p>
            </Modal >
            <Modal
                centered
                closable={false}
                open={loginProgressOpen}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <div style={{ textAlign: 'center' }}>
                    <p>Logging you in...</p>
                    <br />
                    <Spin size="large" />
                </div>
            </Modal >
            <section style={styles.section}>
                <div style={styles.vertical}>
                    <div style={styles.container}>
                        <LoginDialog
                            loading={loading}
                            setLoading={setLoading}
                            loginMessage={loginMessage}
                            tryToLogin={tryToLogin}>
                        </LoginDialog>
                        <div style={styles.line}></div>
                        <LoginSSO
                            loading={loading}
                            setLoading={setLoading}
                            setLoginMessage={setLoginMessage}
                            tryToLogin={tryToLogin}>
                        </LoginSSO>
                    </div>
                    <div style={styles.footer}>
                        <Text style={styles.text}>
                            <p>Don't have an account? Please contact your ClearyX representative.</p>
                        </Text>{" "}
                    </div>
                </div>
            </section>
        </>
    );
}