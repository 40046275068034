import React, { useState, useEffect } from "react";
import { Flex, theme, Layout } from 'antd';
import { getApiUrl, apiGetWithToken, redirectToLogin } from "lib/api.js";
import { DashboardSites } from './Sites.js';
import { DashboardWelcome } from './Welcome.js';
import CXAvatar from "components/CXAvatar/CXAvatar.js";

const { Content } = Layout;

const Dashboard = () => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(0);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    useEffect(() => {
        const url = getApiUrl('/api/user');
        apiGetWithToken(url,
            () => { setLoading(true); },
            (data) => { setUser(data.user); },
            (err) => { redirectToLogin(); },
            () => { setLoading(false); }
        );
    }, [refresh]);

    return (
        <Layout style={{ minHeight: '100vh', }} >
            <Layout style={{ margin: '16px 16px' }} >
                <Flex style={{ paddingBottom: '20px' }}>
                    <Flex justify="start">
                        <img src='/static/ClearyX-logo.png' alt="ClearyX Logo"></img>
                    </Flex>
                    <Flex flex="1" justify="end">
                        <CXAvatar user={user} hasMenu /></Flex>
                </Flex>

                {/* <Header style={{ padding: 0, background: colorBgContainer, }} /> */}
                <Content >
                    <div
                        style={{
                            padding: 40,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Flex gap="large">
                            <DashboardSites user={user} userLoading={loading} />
                            <DashboardWelcome />
                        </Flex>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default Dashboard;