import { getCookie } from 'lib/cookies.js';

const Home = () => {
    // If the user has the token cookie set, send them to the dashboard
    // otherwise send them to login.
    const token = getCookie('token');
    if (token === null || token === '') {
        window.location.href = "/login";
        return 'Redirecting to login page...';
    }
    window.location.href = "/dashboard";
    return 'Redirecting to dashboard...';
};

export default Home;