import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, Link } from "react-router-dom";
import { Table, message, Tag, Space } from 'antd';
import { getApiUrl, apiGetWithToken, requireSiteId, redirectToLogin } from 'lib/api';
import CXLoading from "components/CXLoading";

import CXFileExplorer from "components/CXFileExplorer";

const ListFiles = () => {
    const { site, siteInfo, siteLoading, setBreadcrumb } = useOutletContext();
    const folderId = parseInt(useParams().folderId);

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Files': '/site/' + site.siteId + '/files',
        });
    }, [siteLoading, site, setBreadcrumb]);

    return (
        <>
            <CXFileExplorer site={site} siteInfo={siteInfo} siteLoading={siteLoading} folderId={folderId} />
        </>
    );
};

export default ListFiles;