import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { Flex, Layout, theme } from 'antd';
import { HomeFilled } from '@ant-design/icons';
import { Outlet } from "react-router-dom";
import { getApiUrl, apiGetWithToken, requireSiteId, redirectToLogin } from 'lib/api';
import CXSidebar from 'components/CXSidebar';
import CXAvatar from 'components/CXAvatar';
import CXBreadcrumb from 'components/CXBreadcrumb';
import CXSiteSelector from 'components/CXSiteSelector';

const { Content } = Layout;

const PageLayout = () => {
    const siteId = parseInt(useParams().siteId);
    const [breadcrumb, setBreadcrumb] = useState({});
    const [siteLoading, setSiteLoading] = useState(true);
    const [site, setSite] = useState(null);
    const [siteInfo, setSiteInfo] = useState(null);
    const [user, setUser] = useState(null);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    useEffect(() => {
        if (!requireSiteId(siteId)) {
            return;
        }

        // Create an abort controller for the async api call.
        const controller = new AbortController();
        const signal = controller.signal;

        // todo - make menu work
        const url = getApiUrl('/api/site/' + siteId + '?includeMenu=1&includeUser=1');
        apiGetWithToken(url,
            () => {
                setSiteLoading(true);
                console.log('Loading Site....');
            },
            (data) => {
                // console.log('Site results', data);
                setSite(data.site);
                setSiteInfo(data.info);
                setUser(data.user);
            },
            (err) => { redirectToLogin(err); },
            (cancelled) => {
                if (!cancelled) {
                    setSiteLoading(false);
                    console.log('Site loaded');
                } else {
                    console.log('Site load cancelled');
                }
            },
            null,
            signal,
        );

        // Cleanup function to clear the timeout if the component unmounts
        return () => {
            // Abort in progress apii calls
            // console.debug('Aborting site load');
            controller.abort();
            setSite(null);
        }

    }, [siteId]);

    const siteLink = site ? `/site/${site.siteId}` : '/';

    return (
        <Flex style={{ height: '100vh', minHeight: '100vh', maxHeight: '100vh' }} >
            <Flex id="left" vertical >
                <Flex id="menu">
                    <CXSidebar includeLogo />
                </Flex>
            </Flex>

            <Flex
                flex="1"
                id="mainContent"
                vertical
                style={{ paddingBottom: '40px', paddingLeft: '40px', paddingRight: '40px', backgroundColor: '#f4f4f5', overflowY: 'auto' }}
            >

                <Flex id="topBar" style={{ margin: '20px 0 20px 0', height: '60px', minHeight: '60px', backgroundColor: '#fff', padding: '2px 40px 2px 40px', borderRadius: '8px', border: '1px solid #eee' }}>
                    <Flex align="center">
                        <Link to={siteLink}>
                            <HomeFilled style={{ color: '#888', fontSize: '1.5em' }} />
                        </Link>
                    </Flex>
                    <Flex align="center" style={{ marginLeft: '20px', paddingLeft: '10px', paddingRight: '10px', color: '#888', fontSize: '1.2em' }}>
                        <CXSiteSelector site={site} user={user} />
                    </Flex>
                    <Flex>
                        <Flex align="center" style={{ marginLeft: '60px' }}>
                            <CXBreadcrumb breadcrumb={breadcrumb} />
                        </Flex>
                    </Flex>
                    <Flex align='center' justify="flex-end" flex="1">
                        <CXAvatar user={user} hasMenu></CXAvatar>
                    </Flex>
                </Flex>

                <div style={{ width: '100%', height: '100%' }}>
                    <Outlet context={{
                        siteId,
                        site,
                        setSite,
                        siteInfo,
                        user,
                        siteLoading,
                        setBreadcrumb
                    }} />
                </div>
            </Flex>

        </Flex >
    );
};

export default PageLayout;