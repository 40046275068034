import React, { useState, useEffect } from "react";
import { Progress, Modal } from 'antd';
import { getApiUrl, apiGetWithToken, parseJsonChunk } from 'lib/api';


export function DeleteFiles({ site, open, setOpen, tableItemIds, setSelectedRowKeys, refresh }) {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('');
    const [okButtonText, setOkButtonText] = useState('Delete');
    const [cancelButtonText, setCancelButtonText] = useState('Cancel');
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [okButtonDisplay, setOkButtonDisplay] = useState('inline');
    const [progressVisible, setProgressVisible] = useState(false);
    const [progressPercent, setProgressPercent] = useState(0);


    useEffect(() => {
        setModalText('Are you sure you want to delete these files?  This action is permanent and deleted files may not necessarily be recoverable.');
        setOkButtonText('Delete');
        setCancelButtonText('Cancel');
        setButtonsDisabled(false);
        setConfirmLoading(false);
        setOkButtonDisplay('inline');
        setProgressVisible(false);
        setProgressPercent(0);
    }, [open]);

    function handleOk() {
        setModalText('Deleting files, please wait....');
        setConfirmLoading(true);
        setButtonsDisabled(true);
        setProgressVisible(true);

        var url = new URL(getApiUrl(`/api/site/${site.siteId}/files/delete`));
        url.searchParams.append('tableItemIds', tableItemIds.join(','));

        apiGetWithToken(url.toString(),
            null,
            (data) => {
                //console.log('new data', data);
            },
            (err) => {
                console.error(err);
                setModalText('An unexpected error occurred while trying to delete the files.  Plese refresh the page and try again.');
                setOkButtonText('OK');
                setButtonsDisabled(false);
                setOkButtonDisplay('none');
                setConfirmLoading(false);
            },
            null,
            (progress, chunk) => {
                parseJsonChunk(chunk, (o) => {
                    if (o.done) {
                        refresh();
                        setProgressPercent(100);
                        setModalText('Files are deleted.');
                        setCancelButtonText('Done');
                        setButtonsDisabled(false);
                        setConfirmLoading(false);
                        setOkButtonDisplay('none');
                        setSelectedRowKeys([]);
                    } else {
                        setProgressPercent(Math.round(o.currentFile / o.totalFiles * 100));
                    }
                });
            },
        );

    };

    return (
        <Modal
            title="Delete Confirmation"
            centered
            open={open}
            confirmLoading={confirmLoading}
            okText={okButtonText}
            onOk={handleOk}
            okButtonProps={{ style: { display: okButtonDisplay } }}
            cancelText={cancelButtonText}
            onCancel={() => { setOpen(false); }}
            cancelButtonProps={{ disabled: buttonsDisabled, }}
        >
            <p>{modalText}</p>
            <Progress percent={progressPercent} style={{ display: progressVisible ? 'block' : 'none' }} />
        </Modal >
    );
}