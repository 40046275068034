import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { Table, Space, message } from 'antd';
import CXLoading from "components/CXLoading";
import { apiGetWithToken, getApiUrl, redirectToLogin } from "lib/api";

const AdminTables = () => {
    const { site, siteLoading, setBreadcrumb } = useOutletContext();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [tables, setTables] = useState([]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: '1',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: '2',
        },
        {
            title: 'Items',
            dataIndex: 'numRows',
            key: 'count',
        },
        {
            title: '',
            key: 'action',
            render: (_, table) => {
                const link = '/site/' + table.siteId + '/table/' + table.tableId;
                return (
                    <Space size="middle">
                        <Link to={link}>View</Link>
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Tables': '/admin/' + site.siteId + '/tables',
        });

        const url = getApiUrl('/api/site/' + site.siteId + '/tables');
        apiGetWithToken(url,
            () => { setLoading(true); },
            (data) => {
                console.log(data);
                // Set a key to make List happy.
                for (let i = 0; i < data.tables.length; ++i) {
                    data.tables[i].key = 'key' + (i + 1);
                }
                setTables(data.tables);
            },
            (err) => { messageApi.error(err); },
            () => { setLoading(false); }
        );

        return () => {
            setTables([]);
        }
    }, [siteLoading, site, setBreadcrumb, setLoading, setTables, messageApi]);

    if (siteLoading || loading) {
        return <CXLoading text="Loading tables..." />
    }

    return (
        <>
            <h1>Tables</h1>
            <Table columns={columns} dataSource={tables} />
            {contextHolder}
        </>
    );
};

export default AdminTables;