import React from 'react';
import { Avatar, Popover, Badge } from 'antd';

const CXAvatar = ({ user, hasMenu }) => {

  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div >
  );

  let initials = '';
  if (user && user.name) {
    const s = user.name.split(' ');
    s.forEach(str => {
      initials += str[0];
    });
    initials = initials.toUpperCase();
  } else {
    return (<></>);
  }

  // TODO - make this work
  let badges = 0;

  const inside = (
    <Badge count={badges}>
      <Avatar size='large' shape="square"
        style={{
          backgroundColor: '#dddbea',
          color: '#000',
        }}
      >
        <b>{initials}</b>
      </Avatar>
    </Badge>
  );

  if (hasMenu) {
    return (
      <div style={{ cursor: 'pointer' }}>
        <Popover content={content} title="Title" trigger="click">
          {inside}
        </Popover>
      </div>
    )
  } else {
    return (<div>{inside}</div>)
  }
};

export default CXAvatar;