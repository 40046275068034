import React, { useCallback } from "react";
import { Modal, Tag } from 'antd';
import { BarsOutlined } from '@ant-design/icons';
import { apiPostWithToken, getApiUrl } from "lib/api";

export function FileTags({ file }) {
    const [modal, contextHolder] = Modal.useModal();

    const removeBundle = useCallback((folder, bundleName) => {
        console.log('Remove bundle ' + bundleName + ' from folder ' + folder.name);
        let url = getApiUrl(`/api/site/${folder.siteId}/file/${folder.tableItemId}/removebundle`);
        const values = { bundleName: bundleName };

        apiPostWithToken(url, values,
            null,
            (data) => { /*console.log('TEST', data); */ },
            (err) => { console.error(err); },
        );
    }, []);

    function stripTag(tag) {
        const ndx = tag.indexOf('::');
        if (ndx >= 0) {
            const ndx2 = tag.indexOf('::', ndx + 2);
            if (ndx2 >= 0) {
                return tag.substring(ndx2 + 2).toLowerCase();
            }
        }
        return tag.toLowerCase();
    }

    function tagColor(tag, defaultColor) {
        const ndx = tag.indexOf('::');
        if (ndx >= 0) {
            const ndx2 = tag.indexOf('::', ndx + 2);
            if (ndx2 >= 0) {
                return tag.substring(ndx + 2, ndx2).toLowerCase();
            }
            return tag.substring(ndx + 2).toLowerCase();
        }
        return defaultColor;
    }

    function getFolderTags(folder) {
        let tags = [];

        if (folder.bundles && folder.bundles.length) {
            // console.log(folder.bundles);
            folder.bundles.forEach((name) => {
                tags.push({
                    name: name,
                    icon: <BarsOutlined />,
                    //color: '',
                    onClose: async (e) => {
                        e.preventDefault();
                        // console.log('click');
                        if (await modal.confirm({
                            title: 'Removal Bundle?',
                            content: (
                                <>
                                    Are you sure you want to remove this bundle from the folder?  All previous bundled answers will be lost.
                                </>
                            ),
                        })) {
                            removeBundle(folder, name);
                        }
                    },
                });
            });
        }

        return tags;
    }

    function getFileTags(file) {
        let tags = [];
        return tags;
    }

    function getTags(file) {
        return file.isFolder ? getFolderTags(file) : getFileTags(file);
    }

    return (
        <>
            {
                getTags(file).map((tag) => {
                    return (
                        <Tag
                            style={{ marginTop: '4px', cursor: 'auto' }}
                            color={tag.color}
                            icon={tag.icon} key={tag.name}
                            closeIcon
                            onClose={(e) => { e.preventDefault(); tag.onClose(e); }}
                            onClick={(e) => { e.preventDefault(); }}
                        >
                            {tag.name}
                        </Tag>
                    );
                })}
            {
                file.tags && file.tags.map((tag) => {
                    return (<Tag
                        style={{ marginTop: '4px' }}
                        color={tagColor(tag, 'blue')}
                        key={tag}>{stripTag(tag)}</Tag>);
                })}
            {
                file.remoteTags && file.remoteTags.map((tag) => {
                    return (<Tag
                        style={{ marginTop: '4px' }}
                        color={tagColor(tag, 'blue')}
                        key={tag}>{stripTag(tag)}</Tag>);
                })}
            {contextHolder}
        </>
    );
}
