import React, { useState, useEffect } from "react";
import { Flex, Checkbox, Modal, Upload, message } from 'antd';
import { InboxOutlined, } from '@ant-design/icons';
import { getApiUrl, getAuthorizationHeader } from 'lib/api';

const { Dragger } = Upload;

export function UploadDialog({ site, siteInfo, currentFolderId, setReloadFiles, isUploadOpen, setIsUploadOpen, refresh }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [fileList, setFileList] = useState([]);
    const [okDisabled, setOkDisabled] = useState(false);
    const [autoCloseChecked, setAutoCloseChecked] = useState(true);

    useEffect(() => {
        setFileList([]);
        setOkDisabled(false);
        return () => {
            setFileList([]);
        }

    }, []);

    const uploadProps = {
        name: 'file',
        multiple: true,
        action: getApiUrl('/api/site/' + site.siteId + '/file/' + (currentFolderId ? currentFolderId : site.rootFolderId)),
        //listType: 'picture',
        headers: {
            'Authorization': getAuthorizationHeader(),
        },
        beforeUpload(file, _) {
            if (file.size >= siteInfo.maxFileUploadSize) {
                const msg = 'File ' + file.name + ' is bigger than the max upload file size';
                console.error(msg);
                messageApi.error(msg);
                return false;
            }
            return true;
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        onChange(info) {
            // console.log('info', info);

            // if (info.file.status !== 'uploading') {
            //     console.log(info.file, info.fileList);
            // }
            if (info.file.status === 'done') {
                messageApi.success(`${info.file.name} file uploaded successfully`);
                setReloadFiles(true);
            } else if (info.file.status === 'error') {
                messageApi.error(`${info.file.name} file upload failed.`);
            }

            let uploading = false;
            let allDone = true;

            let newFileList = [...info.fileList];
            for (let i = 0; i < newFileList.length; i++) {
                if (newFileList[i].size >= siteInfo.maxFileUploadSize && newFileList[i].status !== 'error') {
                    newFileList[i].name += ' (too large to upload)';
                    newFileList[i].status = 'error';
                }
                if (newFileList[i].status === 'uploading') {
                    uploading = true;
                }
                if (newFileList[i].status !== 'done') {
                    allDone = false;
                }
            }
            setOkDisabled(uploading);
            setFileList(newFileList);

            if (allDone && autoCloseChecked) {
                setFileList([]);
                setIsUploadOpen(false);
                refresh();
            }
        },
    };

    const onChangeCheckbox = (e) => {
        setAutoCloseChecked(e.target.checked);
    };

    return (
        <>
            {contextHolder}

            <Modal
                title="Upload Files"
                open={isUploadOpen}
                centered
                okText="Close"
                onOk={() => { setFileList([]); setIsUploadOpen(false); }}
                okButtonProps={{ disabled: okDisabled }}
                onCancel={() => { setFileList([]); setIsUploadOpen(false); }}
                cancelButtonProps={{ style: { display: 'none' } }}
                closable={!okDisabled}
                keyboard={!okDisabled}
                maskClosable={!okDisabled}
                width="600px"
            >
                <Flex vertical style={{ width: "100%" }}>
                    <Flex flex="1" style={{ width: "100%" }}>
                        <div style={{ width: "100%", height: "100%" }}>
                            <Dragger {...uploadProps} fileList={fileList} disabled={okDisabled} >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    <b>You may upload {siteInfo.maxFilesPerUpload} files at a time, with a maximum size of {siteInfo.maxFileUploadSize / 1024 / 1024}MB each.</b>
                                </p>
                            </Dragger>
                        </div>
                    </Flex>
                    <Flex style={{ height: "50px" }}>
                        &nbsp;
                    </Flex>
                    <Flex>
                        <Checkbox checked={autoCloseChecked} onChange={onChangeCheckbox}>Close this screen automatically when upload finishes.</Checkbox>
                    </Flex>
                </Flex>

            </Modal >
        </>
    );

};
