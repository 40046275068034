import React from "react";
import { Badge } from 'antd';
import { AppIcon } from "./AppIcon.js";

export function ClickableFile({ file, currentFolderId, onClick, messageApi }) {

    function FileName({ file }) {

        if (file.name === '<prev folder>') {
            return (<i>{file.name}</i>);
        }
        if (file.isFolder && file.tableItemId === currentFolderId) {
            return (<b>{file.name}</b>);
        }
        if (file.answers && file.answers > 0) {
            return <div><Badge offset={[20, 7]} color="#b6b8de" count={file.answers}>{file.name}</Badge></div>
        }
        if (file.metadata && file.metadata > 0) {
            return <div><Badge offset={[26, 7]} color="#b6b8de" count={file.metadata}>{file.name}</Badge></div>
        }
        return (<>{file.name}</>);
    }

    return (
        <>
            <AppIcon file={file} currentFolderId={currentFolderId} />&nbsp; <FileName file={file} />
        </>
    );
}
