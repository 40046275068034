import React, { useEffect, useState } from "react";
import { Modal, Form, Input, message, Button, Flex, Checkbox, Select } from 'antd';
import { getApiUrl, apiPostWithToken } from 'lib/api';

const { TextArea } = Input;

export const EditQuestion = ({ open, setOpen, bundleId, question, setQuestion, site, setRefresh }) => {
    const [enabled, setEnabled] = useState(true);
    const [useTags, setUseTags] = useState(false);
    const [useValueForTag, setUseValueForTag] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [modal, modalContextHolder] = Modal.useModal();
    const [form] = Form.useForm();

    function onClickDelete() {
        modal.confirm({
            title: 'Confirm Question Deletion',
            centered: true,
            content: (
                <>
                    <Flex vertical gap="small">
                        <Flex>
                            <b>Are you absolutely sure you want to delete this question?</b>
                        </Flex>
                        <Flex>
                            This will also delete any answers that were previously generated for this question.
                        </Flex>
                    </Flex>
                </>
            ),
            onOk: () => {
                apiPostWithToken(getApiUrl(`/api/site/${site.siteId}/question/${question.questionId}/delete`), [],
                    null,
                    (data) => { setOpen(false); setRefresh(true); },
                    (err) => { messageApi.error('An unknown error occurred deleting the question, please try again.') },
                );
            },
        });
    }

    function onCreate() {
        form.validateFields().then((values) => {
            values['bundleId'] = bundleId;
            if (question) {
                values['questionId'] = question.questionId;
            }
            console.log(values);
            apiPostWithToken(getApiUrl(`/api/site/${site.siteId}/question`), values,
                () => { setEnabled(false); },
                (data) => {
                    form.resetFields();
                    setOpen(false);
                    setRefresh(true);
                },
                (err) => { messageApi.error('An unknown error occurred, please try again.'); },
                () => { setEnabled(true); },
            );
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    useEffect(() => {
        form.setFieldValue('description', question ? question.description : '');
        form.setFieldValue('question', question ? question.question : '');
        form.setFieldValue('showInDrawer', question ? question.showInDrawer : true);

        form.setFieldValue('tagEnabled', question ? question.tagEnabled : false);
        setUseTags(question ? question.tagEnabled : false);
        form.setFieldValue('tagIsAnswer', question ? question.tagIsAnswer : false);
        setUseValueForTag(question ? question.tagIsAnswer : false);
        form.setFieldValue('tagCustomValue', question ? question.tagCustomValue : '');
        form.setFieldValue('tagColor', question ? question.tagColor : '');

    }, [form, question]);

    const colors = [
        {
            value: 'magenta',
            label: 'Magenta',
        },
        {
            value: 'red',
            label: 'Red',
        },
        {
            value: 'volcano',
            label: 'Volcano',
        },
        {
            value: 'orange',
            label: 'Orange',
        },
        {
            value: 'gold',
            label: 'Gold',
        },
        {
            value: 'lime',
            label: 'Lime',
        },
        {
            value: 'green',
            label: 'Green',
        },
        {
            value: 'cyan',
            label: 'Cyan',
        },
        {
            value: 'blue',
            label: 'Blue',
        },
        {
            value: 'geekblue',
            label: 'GeekBlue',
        },
        {
            value: 'purple',
            label: 'Purple',
        },
    ];

    return (
        <>
            <Modal
                title={question ? "Edit Question" : "Add New Question"}
                destroyOnClose
                centered
                open={open}
                width="800px"
                closable={false}
                footer={[
                    <Flex key="1" style={{ paddingTop: '20px' }}>
                        <Flex flex="1">
                            <Button onClick={onClickDelete} style={{ display: question ? 'inline' : 'none' }} danger>Delete</Button>
                        </Flex>
                        <Flex gap="small">
                            <Button onClick={() => { setOpen(false); }} disabled={!enabled}>Cancel</Button>
                            <Button onClick={onCreate} disabled={!enabled} type="primary">{question ? "Save" : "Create"} Question</Button>
                        </Flex>
                    </Flex>
                ]}
            >
                <br />

                <Form
                    form={form}
                    layout="vertical"
                    disabled={!enabled}
                    labelWrap="true"
                >
                    <Form.Item name="description" label="Description" rules={[{ required: true }]} >
                        <Input />
                    </Form.Item>

                    <Form.Item name="question" label="Question to ask ClearyAI" rules={[{ required: true }]} >
                        <TextArea rows={8} />
                    </Form.Item>

                    <Form.Item name="showInDrawer" valuePropName="checked" style={{ margin: '0px' }}>
                        <Checkbox>Show this question in the file preview drawer</Checkbox>
                    </Form.Item>

                    <Form.Item name="tagEnabled" valuePropName="checked" style={{ margin: '0px' }}>
                        <Checkbox onChange={() => { setUseTags(form.getFieldValue("tagEnabled")); }}>Enable tags for this question</Checkbox>
                    </Form.Item>
                    <Flex vertical style={{ backgroundColor: '#ddd', padding: '10px', border: "1px solid #999" }}>
                        <Form.Item name="tagIsAnswer" valuePropName="checked">
                            <Checkbox onChange={() => { setUseValueForTag(form.getFieldValue("tagIsAnswer")); }} disabled={!useTags} >Use the answer as the tag value</Checkbox>
                        </Form.Item>

                        <Flex gap="middle">
                            <Form.Item name="tagCustomValue" label="Custom tag value">
                                <Input disabled={!useTags || useValueForTag} />
                            </Form.Item>

                            <Form.Item name="tagColor" label="Tag Color">
                                <Select style={{ width: '200px' }} disabled={!useTags} options={colors} />
                            </Form.Item>
                        </Flex>
                    </Flex>
                </Form >
            </Modal >

            {modalContextHolder}
            {contextHolder}
        </>
    );
};
