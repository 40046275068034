import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { message, Table } from 'antd';
import CXLoading from "components/CXLoading";
import { apiGetWithToken, getApiUrl } from "lib/api";
import CXTableWrapper from "components/CXTableWrapper";

// import ReactExport from "react-export-excel";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ViewTable = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const { site, siteLoading, setBreadcrumb } = useOutletContext();
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState([]);
    const [yHeight, setyHeight] = useState(500);

    let params = useParams();
    const tableId = parseInt(params.tableId);
    const viewId = params.viewId === 'pivot' ? params.viewId : parseInt(params.viewId);

    let columns = [];

    useEffect(() => {
        if (siteLoading) {
            return;
        }

        const url = viewId ?
            getApiUrl(`/api/site/${site.siteId}/view/${viewId}`) :
            getApiUrl(`/api/site/${site.siteId}/table/${tableId}`);
        apiGetWithToken(url,
            () => { setLoading(true); },
            (data) => {
                console.log(data);
                let v = viewId ? data.view : data.table;
                for (let i = 0; i < v.fieldValues.length; i++) {
                    v.fieldValues[i].key = 'data' + (i + 1);
                }
                setTable(v);
            },
            (err) => { messageApi.error(err); },
            () => { setLoading(false); }
        );

    }, [siteLoading, site, setBreadcrumb, setLoading, setTable, tableId, viewId, messageApi]);

    useEffect(() => {
        if (loading || siteLoading || !site || !site.name || !table || !table.name) {
            setBreadcrumb({});
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            [table.name]: '/site/' + site.siteId + '/table/' + table.tableId,
        });
    }, [siteLoading, site, setBreadcrumb, table, loading]);

    if (siteLoading || loading) {
        return <CXLoading text="Loading data..." />
    }

    let xScroll = 100;
    for (let i = 0; i < table.fieldList.length; i++) {
        if (viewId || table.fieldList[i].defaultView) {
            let width = 200;
            if (table.fieldList[i].type === 'int') {
                width = 100;
            }
            // console.log(table.fieldList[i].type, width);
            xScroll += width;
            const prop = table.fieldList[i].internalName;

            let filterable = true;
            let fieldValues = [];
            table.fieldValues.forEach((v) => {
                // console.log(prop, v[prop]);
                if (v[prop] && v[prop].length && v[prop].length > 100) {
                    filterable = false;
                }
                if (!fieldValues[v[prop]])
                    fieldValues[v[prop]] = 1;
                else
                    fieldValues[v[prop]] += 1;
            });
            // If more than half the values are unique, filtering seems like a bad idea.
            const fvl = Object.keys(fieldValues).length;
            if (fvl > 20 && (fvl > (table.fieldValues.length * 3 / 4))) {
                // console.log(prop, fvl, table.fieldValues.length);
                filterable = false;
            }

            let filters = [];
            if (!filterable) {
                filters = [
                    {
                        text: 'Exclude Null Values',
                        value: '_ex',
                    },
                    {
                        text: 'Exclude Non-Null Values',
                        value: '_non',
                    },
                ];
            } else {
                // console.log(prop, fieldValues);
                for (const v in fieldValues) {
                    filters.push({
                        text: v + ' (' + fieldValues[v] + ')',
                        value: v,
                    });
                };
            }
            columns.push({
                title: table.fieldList[i].name,
                dataIndex: prop,
                key: prop,
                width: width,
                ellipsis: true,
                filterMode: 'tree',
                filterSearch: true,
                filters: filters,
                onFilter: (value, record) => {
                    //console.log(value, record);
                    if (value === '_ex' && record[prop] === null) return false;
                    if (value === '_non' && record[prop] !== null) return false;
                    if (value === 'null' && record[prop] === null) return true;
                    return value === record[prop];
                },
                sorter: (a, b) => {
                    const n = table.fieldList[i].internalName;
                    // console.log(a[n], b[n]);
                    return a[n] > b[n] ? 1 : -1;
                },
            })
        }
    }

    return (
        <>
            <CXTableWrapper id="wrapper" setyHeight={setyHeight} style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}>
                <CXTableWrapper.Header>
                    <div style={{ fontSize: '1.25em', fontWeight: 'bold' }}>{table.name}</div>
                    <div>{table.description}</div>
                </CXTableWrapper.Header>
                <Table
                    size="middle"
                    bordered={true}
                    pagination={false}
                    virtual
                    scroll={{
                        x: xScroll,
                        y: yHeight,
                    }}
                    columns={columns}
                    dataSource={table.fieldValues}
                />
                {/* <ExcelFile element={<button>Download Data</button>}>
                <ExcelSheet data={table.fieldValues} name={table.name}>
                </ExcelSheet>
            </ExcelFile> */}
            </CXTableWrapper>
            {contextHolder}
        </>
    );
};

export default ViewTable;