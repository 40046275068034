import React, { useState, useEffect } from "react";
import { useOutletContext, useParams, Link } from "react-router-dom";
import { getApiUrl, apiGetWithToken, requireSiteId } from 'lib/api';
import parse from 'html-react-parser';
import CXLoading from "components/CXLoading";
import CXBarchart from "components/CXBarchart";
import CXHorizontalBarchart from "components/CXHorizontalBarchart";
import CXPieChart from "components/CXPieChart";
import CXPowerBI from "components/CXPowerBI";
import { Flex } from "antd";

const Page = () => {
    const pageId = parseInt(useParams().pageId);
    const { site, siteLoading, setBreadcrumb } = useOutletContext();
    const [loading, setLoading] = useState(true);
    const [editable, setEditable] = useState(true);
    const [page, setPage] = useState({});

    useEffect(() => {
        if (siteLoading) {
            return;
        }

        function setTitle(title) {
            setBreadcrumb({
                'Home': '',
                [site.name]: '/site/' + site.siteId,
                'Pages': '/site/' + site.siteId + '/pages',
                [title]: '',
            });
        }

        setTitle('Loading...');

        if (!requireSiteId(site.siteId)) {
            return;
        }
        const url = getApiUrl('/api/site/' + site.siteId + '/page/' + pageId);
        apiGetWithToken(url,
            () => { setLoading(true); },
            (data) => { console.log(data); setTitle(data.page.title); setPage(data.page); setEditable(true); },
            (err) => {
                setTitle('Page not found');
                setPage({
                    title: 'Page not found',
                    content: '<h1>Page not found</h1>We\'re sorry, but for some reason we aren\'t able to load that page.<br /><img src="/static/404.png" width="400px">'
                })
                setEditable(false);
            },
            () => { setLoading(false); }
        );


    }, [siteLoading, site, setBreadcrumb, pageId]);

    if (loading || siteLoading) {
        return <CXLoading text="Loading page..." />
    }

    const content = parse(page.content, {
        transform(reactNode, domNode, index) {
            if (reactNode.type === 'cxbarchart') {
                return (
                    <CXBarchart
                        siteId={site.siteId}
                        // {...reactNode.props}
                        title={reactNode.props.title}
                        viewId={reactNode.props.viewid}
                        tableId={reactNode.props.tableid}
                        primary={reactNode.props.primary}
                        primaryLabel={reactNode.props.primarylabel}
                        secondary={reactNode.props.secondary}
                        secondaryLabels={reactNode.props.secondarylabels}
                        filter={reactNode.props.filter}
                        xAxisKeyName={reactNode.props.xaxiskeyname}
                        yAxisKeyNames={reactNode.props.yaxiskeynames}
                        bgColors={reactNode.props.bgcolors}
                        bgStrokeColors={reactNode.props.bgstrokecolors}
                        activeBgColors={reactNode.props.activebgcolors}
                        activeStrokeColors={reactNode.props.activestrokecolors}
                    />
                );
            }
            if (reactNode.type === 'cxhorizontalbarchart') {
                return (
                    <CXHorizontalBarchart
                        siteId={site.siteId}
                        title={reactNode.props.title}
                        viewId={reactNode.props.viewid}
                        tableId={reactNode.props.tableid}
                        primary={reactNode.props.primary}
                        primaryLabel={reactNode.props.primarylabel}
                        secondary={reactNode.props.secondary}
                        secondaryLabels={reactNode.props.secondarylabels}
                        filter={reactNode.props.filter}
                        xAxisKeyName={reactNode.props.xaxiskeyname}
                        yAxisKeyNames={reactNode.props.yaxiskeynames}
                        bgColors={reactNode.props.bgcolors}
                        bgStrokeColors={reactNode.props.bgstrokecolors}
                        activeBgColors={reactNode.props.activebgcolors}
                        activeStrokeColors={reactNode.props.activestrokecolors}
                    />
                );
            }
            if (reactNode.type === 'cxpiechart') {
                return (
                    <CXPieChart
                        siteId={site.siteId}
                        title={reactNode.props.title}
                        viewId={reactNode.props.viewid}
                        tableId={reactNode.props.tableid}
                        primary={reactNode.props.primary}
                        primaryLabel={reactNode.props.primarylabel}
                        secondary={reactNode.props.secondary}
                        secondaryLabels={reactNode.props.secondarylabels}
                        filter={reactNode.props.filter}
                        innerRadius={reactNode.props.innerradius}
                        outerRadius={reactNode.props.outerradius}
                        xAxisKeyName={reactNode.props.xaxiskeyname}
                        yAxisKeyNames={reactNode.props.yaxiskeynames}
                        bgColors={reactNode.props.bgcolors}
                        bgGradient={reactNode.props.bggradient}
                        bgStrokeColors={reactNode.props.bgstrokecolors}
                        bgStrokeGradient={reactNode.props.bgstrokegradient}
                    />
                );
            } else if (reactNode.type === 'cxpowerbi') {
                return (
                    <CXPowerBI
                        siteId={site.siteId}
                        reportId={reactNode.props.reportid}
                    />
                );
            }
            return reactNode;
        },
    });

    const EditLink = () => {

        if (editable) return (
            <>
                <Link to={`/site/${site.siteId}/page/${pageId}/edit`}>Edit Page</Link>
            </>
        );

        return (<></>);
    }

    return (
        <Flex vertical style={{ padding: '0px', backgroundColor: '#fff', height: '100%' }}>
            <Flex vertical style={{ height: '100%' }}>{content}</Flex>
            <Flex><EditLink /></Flex>
        </Flex>
    );
};

export default Page;