import React from 'react';
import { Button, Checkbox, Form, Grid, Input, theme, Typography } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { getApiUrl } from 'lib/api';

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

export default function LoginDialog({ loading, loginMessage, setNetworkMessage, setModalOpen, tryToLogin }) {
    const screens = useBreakpoint();
    const { token } = useToken();

    // Contact the server and attempt a login.
    async function onFinish(values) {
        return await tryToLogin(getApiUrl('/api/login'), values);
    };

    const styles = {
        container: {
            width: "340px",
        },
        footer: {
            marginTop: token.marginLG,
            textAlign: "center",
            width: "100%"
        },
        forgotPassword: {
            float: "right"
        },
        header: {
            marginBottom: token.marginXL
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
        }
    };

    return (
        <>
            <div style={styles.container}>
                <div style={styles.header}>
                    <img alt="X" src="/static/x.png"></img>
                    <Title style={styles.title}>Sign in</Title>
                    <Text style={styles.text}>
                        <p>Welcome to the ClearyX Client Portal</p>
                        <p>Please enter your account details below to sign in.</p>
                        <p><b><font color="red">{loginMessage}</font></b></p>
                    </Text>
                </div>
                <Form
                    name="normal_login"
                    initialValues={{ remember: true, }}
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark="optional"
                >
                    <Form.Item
                        name="email" rules={[
                            {
                                type: "email",
                                required: true,
                                message: "Please input your Email!",
                            },
                        ]}
                    >
                        <Input
                            prefix={<MailOutlined />}
                            placeholder="Email"
                            disabled={loading ? true : false}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                            disabled={loading ? true : false}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox disabled={loading ? true : false}>Remember me</Checkbox>
                        </Form.Item>
                        <Link disabled={loading ? true : false} style={styles.forgotPassword} href="/login/forgot-password">
                            Forgot password?
                        </Link>
                    </Form.Item>

                    <Form.Item style={{ marginBottom: "0px" }}>
                        <Button block="true" type="primary" htmlType="submit" disabled={loading ? true : false}>
                            Log in
                        </Button>
                    </Form.Item>

                </Form>
            </div >
        </>
    );
}