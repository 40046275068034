import React, { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Table, Tag, Space } from 'antd';
import CXLoading from "components/CXLoading";
import CXAvatar from "components/CXAvatar";

const AdminUsers = () => {
    const { site, users, siteLoading, setBreadcrumb } = useOutletContext();

    const role = (user) => { return user.roles[site.siteId]; }

    const columns = [
        {
            title: '',
            key: 'avatar',
            render: (_, record) => (
                <CXAvatar user={record} />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Groups',
            key: 'groups',
            dataIndex: 'groups',
            render: (_, { groups }) => {
                if (groups && groups[site.siteId]) {
                    const g = groups[site.siteId];
                    return (
                        <>
                            {g.map((group) => {
                                return (
                                    <Tag color="blue" key={group.name}>
                                        {group.name}
                                    </Tag>
                                );
                            })}
                        </>
                    );
                } else {
                    return (<></>);
                }
            },
        },
        {
            title: 'Role',
            key: 'role',
            render: (_, user) => {
                return role(user).name;
            },
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a>View</a>
                </Space>
            ),
        },
    ];


    useEffect(() => {
        if (siteLoading) {
            return;
        }
        setBreadcrumb({
            'Home': '',
            [site.name]: '/site/' + site.siteId,
            'Admin': '/admin/' + site.siteId,
            'Users': '/admin/' + site.siteId + '/users',
        });

    }, [siteLoading, site, users, setBreadcrumb]);

    if (siteLoading || !users) {
        return <CXLoading text="Loading users..." />
    }

    // Set a key to make List happy.
    for (let i = 0; i < users.length; ++i) {
        users[i].key = 'key' + (i + 1);
    }
    // console.log(users);

    return (
        <>
            <h1>Users</h1>
            <Table columns={columns} dataSource={users} />
        </>
    );
};

export default AdminUsers;